<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailForm" title="기본정보">
        <template slot="card-button">
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveEducation"
            @btnCallback="saveCallback"
          />
        </template>
        <template slot="card-detail" class="cardClassDetailForm">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="과목명" :value="educationInfo.educationName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육과정" :value="educationInfo.educationCourseName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육종류1" :value="educationInfo.educationKindCdLargeName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육종류2" :value="educationInfo.educationKindCdSmallName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육구분" :value="educationInfo.educationTypeName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육방법" :value="educationInfo.educationMethodName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="educationInfo.plantNm"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="주관부서" :value="educationInfo.departmentDeptName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="교육평가 및 경비정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-textarea
              :disabled="disabled"
              :rows="5"
              :editable="editable"
              name="educationOverview"
              label="교육총평"
              v-model="educationInfo.educationOverview" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="비고"
              name="remark"
              v-model="educationInfo.remark">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-field
              type="user"
              :editable="editable"
              :disabled="disabled"
              label="평가자"
              name="instructorInformation"
              v-model="educationInfo.instructorInformation">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-text
              suffix="원"
              type="number"
              :editable="editable"
              :disabled="disabled"
              label="집행예산"
              name="executionBudget"
              v-model="educationInfo.executionBudget">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-text
              suffix="원"
              type="number"
              :editable="editable"
              :disabled="true"
              label="예산잔액"
              name="budgetPlan"
              v-model="budgetPlan">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              :editable="editable"
              :disabled="disabled"
              type="edit"
              codeGroupCd="EDU_EVAL_CD"
              itemText="codeName"
              itemValue="code"
              name="evaluationEvalCd"
              label="교육평가"
              v-model="educationInfo.evaluationEvalCd"
            ></c-select>
          </div>
        </template>
      </c-card>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-info',
   components: {
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduCourseId: '',
        documentStatusCd: '',
        eduAttendeeList: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
      },
      title: '',
      selectDate: '',
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      budgetPlan: 0,
      saveUrl: 'transactionConfig.sop.edu.result.update.url',
      saveType: 'PUT',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      completeUrl: '',
      updateMode: false
    };
  },
  computed: {
    disabled() {
      return Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd === 'ESC000010'
    }
  },
  /* eslint-disable no-unused-vars */
  watch: {
    'educationInfo.executionBudget'() {
      this.budgetPlan = Number(this.educationInfo.estimatedEducationExpenses) - Number(this.educationInfo.executionBudget)
    },
    'educationInfo'() {
      this.$emit('transInfo', this.educationInfo);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // list setting
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.updateUrl = transactionConfig.sop.edu.result.update.url;
      this.completeUrl = transactionConfig.sop.edu.result.complete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.param.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data;
          this.updateMode = true;
        },);
      }
    },
    saveEducation() {
      this.saveUrl = this.updateUrl;
      this.saveType = 'PUT';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.educationInfo.chgUserId = this.$store.getters.user.userId
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.eduEducationId = result.data;
      this.getDetail();
    },
  }
};
</script>